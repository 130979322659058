<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import CausesData from "./causes-data";
import AddCause from "./add-cause";

import {
  layoutComputed,
  authComputed,
  causesMethods,
  causesComputed,
} from "@/state/helpers";
/**
 * Causes component
 */
export default {
  page: {
    title: "Causes",
    meta: [{ name: "Causes" }],
  },
  components: {
    Layout,
    PageHeader,
    CausesData,
    AddCause,
  },
  mounted() {
    this.retrieveCauses();
  },
  data() {
    return {
      title: "titles.causes.text",
      busy: false,
      loaderDashboard: false,

      causesData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...causesComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.causes.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...causesMethods,

    retrieveCauses() {
        this.busy = true;
        this.getCausesBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-cause-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-cause-modal");
    },
    add(cause) {
      this.addCause(cause)
        .then((cause) => {
          this.makeToast(
            "Add cause",
            cause.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add cause", error, "danger");
        });
    },
  },
  watch: {
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addCause.text")}}
        </b-button>
        <AddCause @addCause="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CausesData v-bind:causes="causesData" :busy="busy"/>
      </div>
    </div>
  </Layout>
</template>