<script>
/**
 * Causes Data component
 */
import EditModal from "./update-cause.vue";
import { authComputed, causesMethods } from "@/state/helpers";
export default {
  props: ["causes", "busy", "loaderStatus"],
  components: {
    EditModal,
  },
  data() {
    return {
      cause: {},
      causesList: [],

      currentPage: 1,
      perPage: 6,
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != 'USER' && this.loggedIn.user.role != 'OPERATOR') {
        return [
          { key: "name", sortable: false, label: this.$t("titles.causes.text"), class: "tdClass" },
          { key: "actions", sortable: false, label: this.$t("dataTable.tools.text"), class: "tdClass" },
        ];
      } else {
        return [{ key: "name", sortable: false, label: this.$t("titles.causes.text"), class: "tdClass" }];
      }
    },
  },
  mounted() {},
  methods: {
    ...causesMethods,
    showModalEdit() {
      this.$bvModal.show("edit-cause-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-cause-modal");
    },
    edit(cause) {
      this.updateCause(cause)
        .then((cause) => {
          this.makeToast(
            "Update cause",
            cause.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update cause", error, "danger");
        });
    },
    passCause(type, cause) {
      if (type == "edit") {
        this.cause = cause;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t('modals.askValidDelete.text'),
            text: this.$t('modals.cantRevert.text'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delCause(cause.id)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The cause has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      //this.$router.push({name:'alert', params: { serial: record.serial }})
      console.log(record);
    },
  },
  watch: {
    causes(newVal) {
      this.causesList = newVal;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 350px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        :empty-text="$t('dataTable.noCauses.text')"
        :current-page="currentPage"
        :per-page="perPage"
        :items="causesList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{$t('dataTable.loading.text')}}</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="d-inline-flex align-items-center">
            <b-button
              type="button"
              class="mr-2"
              variant="light"
              @click="passCause('edit', data.item)"
              >{{$t("buttons.edit.text")}}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              @click="passCause('delete', data.item)"
              >{{$t("buttons.delete.text")}}
            </b-button>
          </div>
        </template>
      </b-table>
      <EditModal v-bind:cause="cause" @editCause="edit" />
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="causes.length"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>