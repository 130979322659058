<template>
  <div>
    <b-modal
      id="edit-cause-modal"
      ref="edit-cause-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateCause.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="causeEdit.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Type -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="type">{{$t("modals.type.text")}}</label>
          <b-form-select
            id="type"
            v-model="causeEdit.type"
            :options="typesData"
            class="mb-1"
            :state="err.type ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.type }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{$t("buttons.update.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["cause"],
  created() {},
  data() {
    return {
      causeEdit: {
        causeId: null,
        name: null,
        type: null
      },
      err: {
        name: null,
        type: null
      },
      typesData: [
        { value: null, text: this.$t("modals.type.text"), disabled: true },
        { value: 1, text: "Excursion" },
        { value: 2, text: "Offline" },
      ],
    };
  },
  watch: {
    cause(newVal) {
      this.causeEdit = {
        causeId: newVal.id,
        name: newVal.name,
        type: newVal.type
      };
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      /* this.causeEdit = {
        causeId: null,
        name: null,
      }; */
      this.err = {
        name: null,
        type: null
      };
    },
    edit(ok) {
      if (!this.causeEdit.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.causeEdit.type) this.err.type = this.$t("msgError.obligField.text");
      else this.err.type = "";

      if (!this.err.name || !this.err.type) {
        if (this.causeEdit.name != this.cause.name || this.causeEdit.type != this.cause.type) {
          ok();
          this.$emit("editCause", this.causeEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>