<template>
  <div>
    <b-modal
      id="add-cause-modal"
      ref="add-cause-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addCause.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="causeAdd.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Type -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="type">{{$t("modals.type.text")}}</label>
          <b-form-select
            id="type"
            v-model="causeAdd.type"
            :options="typesData"
            class="mb-1"
            :state="err.type ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.type }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{$t("buttons.add.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {

  created() {},
  data() {
    return {
      causeAdd: {
        name: null,
        type: null
      },
      err: {
        name: null,
        type: null
      },
      typesData: [
        { value: null, text: this.$t("modals.type.text"), disabled: true },
        { value: 1, text: "Excursion" },
        { value: 2, text: "Offline" },
      ],
    };
  },
  watch: {
    
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      this.causeAdd = {
        name: null,
        type: null
      };
      this.err = {
        name: null,
        type: null
      };
    },
    add(ok) {
      if (!this.causeAdd.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.causeAdd.type) this.err.type = this.$t("msgError.obligField.text");
      else this.err.type = "";

      if (!this.err.name || !this.err.type) {
        ok();
        this.$emit("addCause", this.causeAdd);
      }
    },
  },
};
</script>